
$txt-color: #798DA3;
$white: #fff;
$color-hover: #86FF00; 
$color-st2 : #09121D;
$font-main: 'resobot-bold';
$font-body: "Space Grotesk", sans-serif ;
$bg-main: #09121D;
$header-height: 100px;
$bgr-body :#0c1226;
$bg-2 :#171F29;
$bg-3 :#1E2835;
$bg-4 :rgba(34,183,143,0.1);
$border-width: 2px;
$corner-size: 3rem;
$font-w6:600;
$font-w7:700;
$color-main1 : #86FF00; 
$black : #000000; 
$color-main2 :#565660; 
$color-3 :#0e2230; 
$w100:100%;
$mb-30: 30px;
$mg32: 32px;
$mb-40: 40px;
$ml-30: -30px;
$center: center;

$padding-right:18px;

$box-shadow: 0 0px 32px 0 rgba(34, 183, 143, 0.5);

$boder-bt: 1px solid rgba(255,255,255,0.2);
$bg-gradient: linear-gradient(to bottom, #22B78F 10%, #E9E9E9 100%);
$bg-gradient2: linear-gradient(180deg, rgba(0, 0, 0, 0) 0.13%, #161616 100%);
$bg-gradient3: linear-gradient(90deg, rgba(0, 0, 0, 0) 0.13%, #161616 100%);
$res_1760: 1760px;
$res_1600: 1600px;
$res_1440: 1440px;
$res_1300: 1300px;
$res_1200: 1200px;
$res_991: 991px;
$res_767: 767px;
$res_600: 600px;
$res_500: 500px;
$res_400: 500px;
